<template>
  <!--begin::Card-->
  <div class="card card-custom" :dir="$t('clan')=='ar'?'rtl':'ltr'">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{$t('TarrifInformation')}}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{$t('ChangeTarrifInformation')}}</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          {{$t('Save Changes')}}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('TarrifName')}}</label>
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="text"
             v-model="tarrif.tarrif_name"
            />
          </div>
        </div>

            <!--<div class="d-flex align-self-center justify-content-center mb-5" v-if="loading===true">
                     <b-spinner variant="primary" style="width:50px; height:50px"   label="Spinning"></b-spinner>
                </div>-->
                
                <div class="form-group row">
                  
               <label class="col-xl-3 col-lg-3 col-form-label "></label>
                <div  class="col-lg-9 col-xl-6">
                  <div class="text-right">
                  <button class="btn btn-primary " @click.prevent="addZone">+ ADD</button>
                </div>
              
                    <b-input-group class="mb-2 mr-sm-2 mb-sm-0 mt-4" v-for="zone in zoneRadius" :key="zone.id">
                  <b-input-group-prepend>
                   <b-input-group-text style="width: 120px"> {{zone.name}}</b-input-group-text>
                  </b-input-group-prepend>

                  <b-form-input
                     v-model="zone.distance"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text> Price </b-input-group-text>
                  </b-input-group-append>
                  <b-form-input
                    v-model="zone.price"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text> {{$t('JOD')}} </b-input-group-text>
                    <b-input-group-text class="bg-danger text-light" @click.prevent="deleteZone(zone.id)"> Delete </b-input-group-text>
                   <!-- <span  ><i class="fas fa-trash-alt mt-5 ml-3 te"></i></span>-->
                  </b-input-group-append>
                </b-input-group>

                  </div>
                  </div>
       
       
      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "QueueInformation",

  props: {
    tarrif: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      createData: [],
       zoneRadius:[
        {
          id:1,price:'',distance:'',name:''
        },
        
      ],
      toggled:false,
      toggleValue:'Fixed',
      loading:true,
      zones:[],
    };
  },
  async mounted() {
    this.zoneRadius = JSON.parse(this.tarrif.zones)
    console.log(this.tarrif.zones)
    //console.log(this.tarrif)
    //this.createData = await this.$store.dispatch("queues/fetchCreateData");
    //console.log(this.tarrif)
    /*await this.$store.dispatch("clients/getZones").then((res)=>{
      this.zones = res
      this.loading = false
    })*/
  },
  methods: {
    deleteZone(id){
      //console.log(id)
      this.zoneRadius = this.zoneRadius.filter(s=>s.id !== id)
      for(var s=0 ; s<this.zoneRadius.length ; s++){
        this.zoneRadius[s].id = s+1
        //console.log(this.tarrif.zones[s])
      }

    },
    addZone(){
      this.zoneRadius.push({
          id:this.zoneRadius.length+1 ,price:'',distance:'',name:'zone' + (this.zoneRadius.length + 1)
        },)

    },
     /* setToggle(){
          if(this.toggled === true){
        this.toggled = false;
        this.toggleValue='Fixed'
        
        

      }
      else if(this.toggled === false){
        this.toggled = true
        this.toggleValue='Percentage'
       
        
      }
      },*/
    /*updateClients(value) {
      this.queue.clients = value;
    },
    updateDrivers(value) {
      this.queue.drivers = value;
    },*/
    save() {
      // set spinner to submit button
      this.tarrif.zones = this.zoneRadius
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch("clientTarrif/update", this.tarrif);

        Swal.fire({
          title: "",
          text:this.$t('QeueAlert'),
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {},
  },
};
</script>
<style >
.te:hover{
  color: rgb(247, 71, 71);
  cursor: pointer;
} 
</style>